import React, { createContext, useState, useContext, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';

// Creazione dei contesti
const LoadingContext = createContext();
const NotificationContext = createContext();

// Provider per il contesto di caricamento
export function LoadingProvider({ children }) {
    const [isLoading, setIsLoading] = useState(false);

    return (
        <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
            {children}
            {isLoading && <LoadingOverlay />}
        </LoadingContext.Provider>
    );
}

// Provider per il contesto delle notifiche
export function NotificationProvider({ children }) {
    const [messages, setMessages] = useState([]);

    const pushMessage = (text, color = 'green') => {
        const id = Date.now();
        setMessages(prev => [...prev, { id, text, color }]);

        // Rimuove il messaggio dopo 30 secondi
        setTimeout(() => {
            setMessages(prev => prev.filter(message => message.id !== id));
        }, 5000);
    };

    return (
        <NotificationContext.Provider value={{ pushMessage }}>
            {children}
            <NotificationList messages={messages} />
        </NotificationContext.Provider>
    );
}

// Custom hooks per usare i contesti
export function useLoading() {
    return useContext(LoadingContext);
}

export function useNotification() {
    return useContext(NotificationContext);
}

// Overlay di caricamento
function LoadingOverlay() {
    return (
        <div style={{
            position: "fixed", top: 0, left: 0, width: "100%", height: "100%",
            backgroundColor: "rgba(128, 128, 128, 0.8)", display: "flex",
            justifyContent: "center", alignItems: "center", zIndex: 9999
        }}>
            <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        </div>
    );
}

// Componente per mostrare le notifiche
function NotificationList({ messages }) {
    return (
        <div style={{ position: 'fixed', bottom: '20px', left: '20px', zIndex: 9999 }}>
            {messages.map(message => (
                <div key={message.id} style={{
                    marginBottom: '10px', padding: '10px 20px', borderRadius: '5px',
                    backgroundColor: message.color === 'red' ? '#f8d7da' : '#d4edda',
                    color: message.color === 'red' ? '#721c24' : '#155724',
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'
                }}>
                    {message.text}
                </div>
            ))}
        </div>
    );
}
