import React, { useState, useRef, useEffect } from 'react';
import './Filters.scss'; // Make sure to create and import the appropriate CSS file
import { Container, Form, Row, Col } from 'react-bootstrap';
import { Dropdown, ButtonGroup } from 'react-bootstrap';
const Ripple = ({color, setAttentionLevel, attentionLevel, currentAttentionLevel}) => {

  const [ripples, setRipples] = useState([]);
  const buttonRef = useRef(null);

  const animateRipple = (e) => {
    const el = buttonRef.current;
    const pos = el.getBoundingClientRect();

    setRipples(prevRipples => [
      ...prevRipples,
      {
        x: e.clientX - pos.left,
        y: e.clientY - pos.top,
        show: true
      }
    ]);
    setAttentionLevel(attentionLevel);
    
  };

  const rippleEnd = (index) => {
    setRipples(prevRipples => {
      const newRipples = [...prevRipples];
      newRipples[index].show = false;
      return newRipples;
    });
  };
  return (
    <button
      className="ti-btn"
      style={{
        color: 'white',
        backgroundColor: color,
        ...(currentAttentionLevel === attentionLevel ? { boxShadow: `0 0 5px 5px ${color}` } : {}),
        borderRadius: '50%',
      }}
      ref={buttonRef}
      onClick={(e) => animateRipple(e, color)}
    >              {attentionLevel}
      <div className="ripple-container">
        {ripples.map((ripple, i) => (
          ripple.show && (
            <label
              key={'ripple-' + i}
              className="ripple"
              style={{
                top: ripple.y + 'px',
                left: ripple.x + 'px'
              }}
              onAnimationEnd={() => rippleEnd(i)}
            />
          )
        ))}
      </div>
    </button>
  );
};



const RangeSlider = ({ min, max, value, step, onChange }) => {
  const [minValue, setMinValue] = useState(value ? value.min : min);
  const [maxValue, setMaxValue] = useState(value ? value.max : max);

  useEffect(() => {
    if (value) {
      setMinValue(value.min);
      setMaxValue(value.max);
    }
  }, [value]);

  const handleMinChange = e => {
    e.preventDefault();
    const newMinVal = Math.min(+e.target.value, maxValue - step);
    if (!value) setMinValue(newMinVal);
    onChange({ min: newMinVal, max: maxValue });
  };

  const handleMaxChange = e => {
    e.preventDefault();
    const newMaxVal = Math.max(+e.target.value, minValue + step);
    if (!value) setMaxValue(newMaxVal);
    onChange({ min: minValue, max: newMaxVal });
  };

  const minPos = ((minValue - min) / (max - min)) * 100;
  const maxPos = ((maxValue - min) / (max - min)) * 100;

  return (
    <div className="wrapper">
      <div className="input-wrapper">
        <input
          className="input"
          type="range"
          value={minValue}
          min={min}
          max={max}
          step={step}
          onChange={handleMinChange}
        />
        <input
          className="input"
          type="range"
          value={maxValue}
          min={min}
          max={max}
          step={step}
          onChange={handleMaxChange}
        />
      </div>

      <div className="control-wrapper">
        <div className="control" style={{ left: `${minPos}%` }} />
        <div className="rail">
          <div
            className="inner-rail" 
            style={{ left: `${minPos}%`, right: `${100 - maxPos}%` }}
          />
        </div>
        <div className="control" style={{ left: `${maxPos}%` }} />
      </div>
    </div>
  );
};

const HighwaySelector = ({ highways, highway, setHighways }) => {
  return (
    <Dropdown as={ButtonGroup}
      style={{
        backgroundColor: '#F8F9FA',
        color: '#495057',
        borderColor: '#3b71ca',
        marginTop: '10px',
        marginBottom: '10px',
      }}
    >
      <Form.Group>
        <Form.Control
          style={{

            backgroundColor: '#F8F9FA',
            color: '#495057',
            borderColor: '#3b71ca',
          }}
          list="highwayList"
          onChange={(e) => setHighways(e.target.value)}
          placeholder="Seleziona l'autostrada"
          value={highway}
        ></Form.Control>
        <datalist id="highwayList">
          {highways.map((item) => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </datalist>
      </Form.Group>

      <Dropdown.Toggle style={{
        backgroundColor: '#F8F9FA',
        color: '#495057',
        borderColor: '#3b71ca',

      }}
      
      className='CustomDropdown '
      split
      id="dropdown-split-basic" />

      <Dropdown.Menu
      style={{

        overflowY: 'scroll',
        maxHeight: '200px',
      }}>
        {highways.map((el, index) => (
          <Dropdown.Item key={index} onClick={() => setHighways(el)}>
            {el}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};



function Filters(props) {
  const {highways, highway ,setHighways, setAttentionLevel, setFilterLength, filterLength,currentAttentionLevel } = props;
  return (
    <Container className="Filters">
      <Container className="ColorFilter">
        <Row>
          <Ripple currentAttentionLevel={currentAttentionLevel} attentionLevel={4} setAttentionLevel={setAttentionLevel} color="#C23B22"/> {/*red*/}
          <Ripple currentAttentionLevel={currentAttentionLevel} attentionLevel={3} setAttentionLevel={setAttentionLevel} color="#FF964F"/> {/*orange*/}
        </Row>
        <Row>
          <Ripple currentAttentionLevel={currentAttentionLevel} attentionLevel={2} setAttentionLevel={setAttentionLevel} color="#FFEE8C"/> {/*yellow*/}
          <Ripple currentAttentionLevel={currentAttentionLevel} attentionLevel={1} setAttentionLevel={setAttentionLevel} color="#56AE57"/> {/*green*/}
        </Row>
      </Container>
      <Container>
        <RangeSlider onChange={setFilterLength} step={5} min={0} max={1000} value={filterLength} />
        <p style={{
          textAlign: 'center',
        }}><span>{filterLength.min}</span>-<span>{filterLength.max}</span> <span>m</span></p>
        </Container>
      <Container>
        <HighwaySelector highways={highways} highway={highway} setHighways={setHighways}/>
      </Container>
    </Container>
  );
}


export default Filters;
