import {useEffect, useState} from "react";
import {API} from "../../api/api";
import {Container, Table} from "react-bootstrap";
import {Link} from "react-router-dom";

export function AllSimulations() {
    const [simulations, setSimulations] = useState([])

    useEffect(() => {
        API.getSimulations()
            .then(data => {
                setSimulations(data)
            });
    }, []);

    return <Container className="mt-4">
        <Table striped bordered>
            <tbody>
            <tr>
                <th>Id</th>
                <th>Name</th>
                <th># Conci</th>
                <th># Scenari</th>
            </tr>
            {simulations.map((sim, i) => <tr key={i}>
                <td>{sim.simulation_id}</td>
                <td>
                    <u><Link to={"/simulations/"+sim.simulation_id} className={"nav-link"} >
                        {sim.simulation_name}
                    </Link></u>
                </td>
                <td>{sim.units_codes.length}</td>
                <td>{sim.scenarios.filter(sc => !sc.deleted).length}</td>
            </tr>)}
            </tbody>
        </Table>
    </Container>

}