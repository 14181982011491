import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, GeoJSON, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import geoJsonData from '../../data/liguria.json';
import { Container, Button, Col } from "react-bootstrap";
import AnalysisSelect from "../AnalysisSelect/AnalysisSelect";
import Filters from "../Filters/Filters";
import HighwaysList from "../HighwaysList/HighwaysList";

import './map-canva.css';


function create_random(criticality,size) {
    const newarr = [];
    for (let i = 0; i < 26; i++) {
        newarr.push([]);
        for (let j = 0; j < size; j++) {
            let value = Math.floor(Math.random() * 3) + 1;
            if (Math.random() < criticality / 10) {
                value = 4;
            }
            newarr[i].push(value);
        }
    }
    
    return newarr;
}



const calculateCenterAndZoom = (geoJson) => {
    console.log(geoJson);
    if (!geoJson || !geoJson.features || geoJson.features.length === 0) {
        return {
            center: [0, 0],
            zoom: 0
        };
    }

    const bounds = getBounds(geoJson.features);
    console.log("bounds", bounds);
    const center = getCenter(bounds);
    
    console.log("center", center);
    const zoom = getZoom(bounds);

    return {
        center,
        zoom
    };
};

const getBounds = (features) => {
    let minLat = 90, maxLat = -90, minLng = 180, maxLng = -180;

    features.forEach(feature => {
        const coordinates = feature.geometry.coordinates;
        traverseCoordinates(coordinates);

        function traverseCoordinates(coords) {
            if (Array.isArray(coords[0])) {
                coords.forEach(coord => traverseCoordinates(coord));
            } else {
                const [lng, lat] = coords;
                if (lat < minLat) minLat = lat;
                if (lat > maxLat) maxLat = lat;
                if (lng < minLng) minLng = lng;
                if (lng > maxLng) maxLng = lng;
            }
        }
    });

    return { minLat, maxLat, minLng, maxLng };
};

const getCenter = ({ minLat, maxLat, minLng, maxLng }) => {
    const centerLat = (minLat + maxLat) / 2;
    const centerLng = (minLng + maxLng) / 2;
    return [centerLat, centerLng];
};

const getZoom = ({ minLat, maxLat, minLng, maxLng }) => {
    const bboxWidthLng = maxLng - minLng;
    const bboxWidthLat = maxLat - minLat;

    const zoomLng = Math.floor(Math.log2(360 / bboxWidthLng));
    const zoomLat = Math.floor(Math.log2(180 / bboxWidthLat));
    const zoom = Math.min(zoomLng, zoomLat);

    return zoom < 0 ? 0 : zoom;
};


const highways=[];


const highwaysData = {
    "type": "FeatureCollection",
    "features": geoJsonData.features.filter(el => {
        if(el.properties.ref && !highways.includes(el.properties.ref)){
            highways.push(el.properties.ref);
        }
    
        return el.properties.operator === "Autostrade per l' Italia S.P.A." ||
        el.properties.operator === "Autostrade per l'Italia S.P.A."
    }
    )
};

const ourGalleries = [
    "Galleria Cogoleto I",
    "Galleria Cogoleto II",
    "Galleria Beuca",
    "Galleria Maxetti",
    "Galleria San Giacomo",
    "Galleria Terralba",
    "Galleria Lepri"
];

function haversineDistance(coord1, coord2) {
    const R = 6371; // Radius of Earth in kilometers
    const lat1 = coord1[1] * (Math.PI / 180); // Convert latitude from degrees to radians
    const lon1 = coord1[0] * (Math.PI / 180); // Convert longitude from degrees to radians
    const lat2 = coord2[1] * (Math.PI / 180); // Convert latitude from degrees to radians
    const lon2 = coord2[0] * (Math.PI / 180); // Convert longitude from degrees to radians
    
    const dLat = lat2 - lat1;
    const dLon = lon2 - lon1;
    
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
              Math.cos(lat1) * Math.cos(lat2) * 
              Math.sin(dLon / 2) * Math.sin(dLon / 2);
    
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    
    return R * c*1000; // Distance in meters
  }



highwaysData.features = highwaysData.features.map(el => {

    if (ourGalleries.includes(el.properties.name)) {
        el.properties.tunnel_length = haversineDistance(el.geometry.coordinates[0], el.geometry.coordinates[el.geometry.coordinates.length - 1]);
        el.properties.sgg = create_random(10,el.geometry.coordinates.length);
        el.properties.slo = create_random(10,el.geometry.coordinates.length);
        el.properties.sgg_slo = create_random(10,el.geometry.coordinates.length);
        el.properties.geo = create_random(10,el.geometry.coordinates.length);
        el.properties.sis = create_random(10,el.geometry.coordinates.length);
        el.properties.std = create_random(10,el.geometry.coordinates.length);
        el.properties.idr = create_random(10,el.geometry.coordinates.length);
        el.properties.Complessiva = create_random(10,el.geometry.coordinates.length);
    } else if (el.properties.name && el.properties.name.includes("Galleria")) {
        el.properties.tunnel_length = haversineDistance(el.geometry.coordinates[0], el.geometry.coordinates[el.geometry.coordinates.length - 1]);
        el.properties.sgg = create_random(1,el.geometry.coordinates.length);
        el.properties.slo = create_random(1,el.geometry.coordinates.length);
        el.properties.sgg_slo = create_random(1,el.geometry.coordinates.length);
        el.properties.geo = create_random(1,el.geometry.coordinates.length);
        el.properties.sis = create_random(1,el.geometry.coordinates.length);
        el.properties.std = create_random(1,el.geometry.coordinates.length);
        el.properties.idr = create_random(1,el.geometry.coordinates.length);
        el.properties.Complessiva = create_random(1,el.geometry.coordinates.length);
    }
    return el;
});

const analysis_classes = [
    "sgg",
    "slo",
    "sgg_slo",
    "geo",
    "sis",
    "std",
    "idr",
    "Complessiva"
];


const getColorBasedOnCDA = (feature, analysis_class, selectedSections) => {
    if (feature?.properties?.name && feature.properties.name.includes("Galleria")) {
        const final_class = analysis_classes.includes(analysis_class) ? analysis_class : "Complessiva";
        if(selectedSections && selectedSections.includes(feature.properties.segment_code)){
            return "#3b71ca";
        }
        if(feature.properties[final_class].length > 0) {
            const avg = feature.properties[final_class][0].reduce((a, b) => a + b, 0) / feature.properties[final_class][0].length;
            let color = "";
            switch (Math.round(avg)) {
                case 1:
                    color = "#56AE57";
                    break;
                case 2:
                    color = "#FFEE8C";
                    break;
                case 3:
                    color = "#FF964F";
                    break;
                case 4:
                    color = "#C23B22";
                    break;
                default:
                    color = "#3b71ca";
            }
            return color;
        }else {
            let color = "";
            switch (Math.round(feature.properties[final_class])) {
                case 1:
                    color = "#56AE57";
                    break;
                case 2:
                    color = "#FFEE8C";
                    break;
                case 3:
                    color = "#FF964F";
                    break;
                case 4:
                    color = "#C23B22";
                    break;
                default:
                    color = "#3b71ca";
            }
            return color;
        }
    } else {
        return "#3b71ca";
    }
};

const divideLineStringIntoSegments = (feature) => {
    if (feature.geometry.type === "LineString") {
        const segments = [];
        const coordinates = feature.geometry.coordinates;
        for (let i = 0; i < coordinates.length - 1; i++) {
            const segment = {
                "type": "Feature",
                "id": feature.id + "_segment_" + i,
                "geometry": {
                    "type": "LineString",
                    "coordinates": [
                        coordinates[i],
                        coordinates[i + 1]
                    ]
            },
            "properties": {
                "segment_code": feature.properties.segment_code,
                "name": feature.properties.name+"_segment_"+i,
                "@id": feature.properties["@id"],
                "operator": feature.properties.operator,
                "sgg": feature.properties.sgg?.[0][i],
                "slo": feature.properties.slo?.[0][i],
                "sgg_slo": feature.properties.sgg_slo?.[0][i],
                "geo": feature.properties.geo?.[0][i],
                "sis": feature.properties.sis?.[0][i],
                "std": feature.properties.std?.[0][i],
                "idr": feature.properties.idr?.[0][i],
                "Complessiva": feature.properties.Complessiva?.[0][i]
            }
        };
            segments.push(segment);
        }

        return {
            "type": "FeatureCollection",
            "features": segments
        };
    } else {
        return null; 
    }
};

const ZoomSegment = ({ setShowSegments, newFilters, highwaysData, segment, fetchedData, centerAndZoom }) => {
    const map = useMap();
    let actualData = { ...highwaysData };

    if (fetchedData) {
        const filteredFeatures = actualData.features.filter(highway =>
            fetchedData.some(data => data.segment_code === highway.properties.segment_code)
        );
        actualData.features = filteredFeatures;  
    }

    useEffect(() => {
        if (centerAndZoom.center && centerAndZoom.zoom) {
            map.setView(centerAndZoom.center, centerAndZoom.zoom);
        }
    }, [centerAndZoom, map]);
    

    useEffect(() => {
        const handleMove = () => {
            
            const bounds = map.getBounds();
            let segmentsCount = 0;
            const segmentsInBounds = [];
            
            const featuresInBounds = actualData.features.filter(feature => {
                return feature.geometry.coordinates.some(([lng, lat]) => bounds.contains([lat, lng]));
            });

            featuresInBounds.forEach(feature => {
                const segments = divideLineStringIntoSegments(feature);
        
                const filteredSegments = segments.features.filter(segment => {
                    const [start, end] = segment.geometry.coordinates;
                    const isInBounds = bounds.contains([start[1], start[0]]) || bounds.contains([end[1], end[0]]);
                    if (isInBounds) segmentsCount++;
                    return isInBounds;
                });
        
                if (filteredSegments.length > 0) {
                    segmentsInBounds.push(...filteredSegments);
                }
            });
        
            if (featuresInBounds.length < 20 && featuresInBounds.length > 0) {
                setShowSegments({
                    "type": "FeatureCollection",
                    "features": segmentsInBounds
                },newFilters );
            } else {
                setShowSegments({
                    "type": "FeatureCollection",
                    "features": featuresInBounds
                }, newFilters);
            }
        };

        handleMove();
        map.on('moveend', handleMove);
        if (segment) {
            const EffectiveSegment = actualData.features.filter(el => el.properties.name === segment);
            const centerZoom=calculateCenterAndZoom({
                "type": "FeatureCollection",
                "features": [...EffectiveSegment]
            },1000);
            map.setView(centerZoom.center, centerZoom.zoom + 2);
        }
        return () => {
            map.off('moveend', handleMove);
        };
    }, [map, highwaysData, newFilters, segment, fetchedData]); 

    return null;
};




const MapCanva = (props) => {
    const [analysis_class, setAnalysis_class] = useState("Complessiva");
    const { fetchedData, selectedSections } = props;
    const [showSegments, setShowSegments] = useState(highwaysData);
    const [centerAndZoom, setCenterAndZoom] = useState(calculateCenterAndZoom(showSegments));

    useEffect(() => {
    if (fetchedData) {
        const filteredFeatures = highwaysData.features.filter(highway =>
            fetchedData.some(data => data.segment_code === highway.properties.segment_code)
        );
        const updatedData = {
            ...highwaysData,
            features: filteredFeatures
        };
        setShowSegments(updatedData);
        setCenterAndZoom(calculateCenterAndZoom(updatedData));
    }
}, [fetchedData]);
    const [filters, setFilters] = useState({
        attentionLevel: 0,
        length: { min: 0, max: 1000 },
        highway: "",
        segment: ""
    });   



    const handleSetFilter = (newFilters) => {
        setFilters(newFilters);
        let filteredSegments = {
            ...highwaysData,
            features: highwaysData.features.filter(highway =>
                fetchedData.some(data => data.segment_code === highway.properties.segment_code)
            )
        };
        handleSetShowSegments(filteredSegments, newFilters);
    };

    const handleSetShowSegments = (filteredSegments, newFilters) => {
        newFilters = newFilters || filters;
        if (newFilters.attentionLevel !== 0) {
            filteredSegments = AttentionLevelFilter(filteredSegments, newFilters.attentionLevel);
        }
        if (newFilters.length.min !== 0 || newFilters.length.max !== 1000) {
            filteredSegments = LengthFilter(filteredSegments, newFilters.length);
        }
        if (newFilters.highway !== "") {
            filteredSegments = HighwayFilter(filteredSegments, newFilters.highway);
        }
        if (newFilters.segment !== "") {
            filteredSegments = SegmentFilter(filteredSegments, newFilters.segment);
        }
        setShowSegments(filteredSegments);
    };

    const SegmentFilter = (Segments,segment) => {
        const filteredSegments = { ...Segments, features: [...Segments.features] };
        filteredSegments.features = filteredSegments.features.filter(el => {
            return el.properties.name?.includes(segment);
        });
        return filteredSegments;
    }
    
    const HighwayFilter = (Segments,highway) => {
        const filteredSegments = { ...Segments, features: [...Segments.features] };
        filteredSegments.features = filteredSegments.features.filter(el => {
            return el.properties.ref === highway;
        });
        return filteredSegments;
    }


    const AttentionLevelFilter = (Segments,level) => {
        const filteredSegments = { ...Segments, features: [...Segments.features] };
                filteredSegments.features = filteredSegments.features.filter(el => {
                    if (el.properties.name && el.properties.name.includes("Galleria")) {
                        if (el.properties[analysis_class] && Array.isArray(el.properties[analysis_class][0])) {
                            const avg = el.properties[analysis_class][0].reduce((a, b) => a + b, 0) / el.properties[analysis_class][0].length;
                            return Math.round(avg) === level;
                        } else {
                            return Math.round(el.properties[analysis_class]) === level;
                        }
                    }
                    return false;
                });
                return filteredSegments;
            }


    function LengthFilter(Segments,length) {
        const filteredSegments = { ...Segments, features: [...Segments.features] };
            filteredSegments.features = filteredSegments.features.filter(el => {
                if (el.properties.name && el.properties.name.includes("Galleria")) {
                    return el.properties.tunnel_length >= length.min && el.properties.tunnel_length <= length.max;
                }
                return false;
            });
        return filteredSegments;
        }


    const handleSetSegment = (segment) => {
        if (segment === filters.segment) {
            handleSetFilter({
                ...filters,
                segment: ""
            });
        } else {
                
            handleSetFilter({
                ...filters,
                segment: segment
            });
        }
    }

    const handleSetFilterLength = (Length) => {
       handleSetFilter({
            ...filters,
            length: Length
       });
    }

    const handleSetFilterHighway = (highway) => {
        if (highway === filters.highway) {
            handleSetFilter({
                ...filters,
                highway: ""
            });
        } else {
            handleSetFilter({
                ...filters,
                highway: highway
            });
        }
    };
    

    const handleSetFilterAttentionLevel = (level) => {
        if (level === filters.attentionLevel) {
            handleSetFilter({
                ...filters,
                attentionLevel: 0
            });
        } else {
        
        if (1 <= level && level <= 4) {
            handleSetFilter({
                ...filters,
                attentionLevel: level
            });
            }
        };
    };



    const geoJsonStyle = (feature) => 
        {
            return {
        color: getColorBasedOnCDA(feature, analysis_class, selectedSections),
        weight: 7,
    }};


    const onEachFeature = (feature, layer) => {
        layer.on({
            click: props.customOnClick? props.customOnClick : (e) => {
                alert(`ID: ${feature.properties.name} [${feature.id}]`);
            }
        });
    };


    const filterProps = {
        highways,
        highway: filters.highway,
        setHighways: handleSetFilterHighway,
        currentAttentionLevel: filters.attentionLevel,
        filterLength: filters.length,
        setFilterLength: handleSetFilterLength,
        setAttentionLevel: handleSetFilterAttentionLevel
      };


      
      
    return (
    <Container className='MapManager'>
            <Container className='Selector'>
            <h1>Filters</h1>
                <AnalysisSelect selectedClass={analysis_class} analysis_classes={analysis_classes} setAnalysis_class={setAnalysis_class} />
                <Filters {...filterProps}  />                

            </Container>
            <Col>
                <Container>
                    <MapContainer
                        center={centerAndZoom.center}
                        zoom={centerAndZoom.zoom + 2}
                        style={{ height: "70vh", width: "1000px" }}
                    >
                        <TileLayer
                            url={
                                 "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            }
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        />

                        {showSegments && (
                            <GeoJSON
                                key={"num_segments_" + showSegments.features.length}
                                data={showSegments}
                                style={geoJsonStyle}
                                onEachFeature={onEachFeature}
                            />
                        )}

                        <ZoomSegment centerAndZoom={centerAndZoom} newFilters={filters} segment={filters && filters.segment} setShowSegments={handleSetShowSegments} highwaysData={highwaysData} fetchedData={fetchedData} />
                    </MapContainer>
                </Container>
            </Col>
            <Col>
                <HighwaysList selectedSections={selectedSections} filters={filters} highways={showSegments.features} analysis_class={analysis_class} setSegment={handleSetSegment} getColorBasedOnCDA={getColorBasedOnCDA} />            
            </Col>
        </Container>
    );
};

export default MapCanva;