import {Tab, Tabs} from "react-bootstrap";
import {RulesManager} from "../configurators/rules-manager";
import {InterventionManager} from "../configurators/interventions-configurator";

export function ConfigRulesAndParams({simulation, setDirtyFlag, interventions, rules, setRules, refresh}) {

    return <>
        <Tabs
            defaultActiveKey="rule"
        >
            <Tab eventKey="rule" title="Rules">
                <RulesManager
                    interventions={interventions}
                    simulation={simulation}
                    refresh={() => setDirtyFlag(x => x + 1)}
                    rules={rules}
                    setRules={setRules}
                />
            </Tab>
            {/*<Tab eventKey="profile" title="Profile">*/}
            {/*    <InterventionManager*/}
            {/*        interventions={interventions}*/}
            {/*        refresh={() => setDirtyFlag(x => x + 1)}*/}
            {/*    />*/}
            {/*</Tab>*/}
        </Tabs>
    </>
}