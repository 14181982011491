import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import {Link} from "react-router-dom";

export function TecneTamNavBar() {

    return <>
        <Navbar
            expand="lg"
            className="bg-body-tertiary"
            style={{borderBottom: "3px solid #0061a1"}}
        >
            <Container>
                <Navbar.Brand href="\">Tecne - TAM Tool</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">

                        <Link to={"/new-simulation"} className={"nav-link"} >Nuova Simulazione</Link>
                        <Link to={"/simulations"} className={"nav-link"} >Simulazioni</Link>
                        <Link to={"/new-interventions"} className={"nav-link"} >Nuovi Interventi</Link>

                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    </>

}