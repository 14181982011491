import {Fragment, useEffect, useRef, useState} from "react";
import {LittleSquareTable} from "../../../components/little-square-table/little-square-table";
import {Button, ButtonGroup, Tab, Tabs} from "react-bootstrap";

const NONE_INTERVENTION = "----"

export function ManualScheduler(
    {
        simulation,
        interventions,
        dirtyFlag,
        setDirtyFlag,
        interventionPlans,
        setInterventionPlans,
        computedUnits,
        setComputedUnits
    }) {

    const name2color = Object.fromEntries(interventions.map(i => [i.intervention_name, i.intervention_color]))

    // const [interventionPlans, setInterventionPlans] = useState(baseIP)
    const [activeIntervention, setActiveIntervention] = useState(NONE_INTERVENTION)

    const updateIntervention = (simulationTime, unitId, kind) => {
        setInterventionPlans(ip => {
            ip[simulationTime][unitId] = activeIntervention
            return [...ip]
        })
        setDirtyFlag(x => x + 1)
    }

    return <Fragment>

        <ButtonGroup aria-label="Basic example" className="mb-3 mt-2">
            <Button
                variant="secondary"
                className={"manual-scheduler-buttons"}
                onClick={() => setActiveIntervention(NONE_INTERVENTION)}
            >Cancella</Button>
            {interventions.map((intervention, i) => <Fragment key={i}>
                <Button
                    className={"manual-scheduler-buttons"}
                    style={{backgroundColor: intervention.intervention_color}}
                    onClick={() => setActiveIntervention(intervention.intervention_name)}
                >{intervention.intervention_name}</Button>
            </Fragment>)}
        </ButtonGroup>

        {computedUnits && <LittleSquareTable
            years={simulation.config.simulation_duration}
            firstYear={simulation.config.current_year}
            params={[interventionPlans, computedUnits.tunnel_rehab]}
            // param={interventionPlans}
            segments={simulation.segments}
            Square={SelectSquare}
            squareProps={{updateIntervention, interventionPlans, name2color}}
        />}


    </Fragment>
}

export function ScheduleResults({simulation, computedUnits}) {

    const tabs = {
        "SGG-SLO": "param_sgg_slo",
        "COM": "param_com",
        "SGG": "param_sgg",
        "SLO": "param_slo",
    }

    if (!simulation || !computedUnits)
        return <>Loading...</>
    return <>
        <Tabs
            className="mb-3"
        >
            {Object.entries(tabs).map(([tab_name, parameter], i) => (
                <Tab eventKey={tab_name} title={tab_name} key={i}>
                    <LittleSquareTable
                        years={simulation.config.simulation_duration}
                        firstYear={simulation.config.current_year}
                        param={computedUnits[parameter]}
                        segments={simulation.segments}
                        Square={CdaSquare}
                        squareProps={{}}
                    />
                </Tab>)
            )}
        </Tabs>
    </>
}

function CdaSquare({unitValue}) {
    const colors = {
        1: "#91ce4f",
        2: "#fde498",
        3: "#fdbe00",
        4: "#fd7b7f",
    }
    return (
        <td
            className="little-square-inner user-select-none text-center"
            style={{backgroundColor: colors[unitValue]}}
        >
            {unitValue}
        </td>
    );
}

function SelectSquare({unitValue, unitId, simulationTime, updateIntervention, interventionPlans, name2color}) {
    // return <td>{JSON.stringify(unitValue)}</td>

    const value = unitValue[0] !== NONE_INTERVENTION ? unitValue[0] : unitValue[1]
    const handleMouseOver = (event) => {
        // Verifica se il tasto sinistro del mouse è premuto
        if (event.buttons === 1) {
            updateIntervention(simulationTime, unitId, 1);
        }
    };

    let color = name2color[value]
    if (color && unitValue[0] === NONE_INTERVENTION)
        color = color + "6e" // schiarisce gli interventi del simulatore

    return (
        <td
            className="little-square-inner user-select-none"
            style={{
                cursor: "pointer",
                backgroundColor: color,
            }}
            onClick={() => updateIntervention(simulationTime, unitId, 1)}
            onMouseOver={handleMouseOver}
            onMouseDown={handleMouseOver}
        >
            {(value !== '----' ? value : null) || <>&nbsp;</>}
        </td>
    );
}
