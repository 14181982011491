import React, { useState } from 'react';
import { ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, LabelList } from 'recharts';
import { Table, Form } from "react-bootstrap";
import Color from 'color';
import { useResizeDetector } from 'react-resize-detector';

export function TamScatterChart({ simulation }) {
    const { width, height, ref } = useResizeDetector();
    const [axis, setAxis] = useState({ x: 'cost', y: 'risk' });
    const _scenarios = simulation.scenarios.filter(sc => !!sc.results && !sc.deleted);

    const axisOptions = [ 'cost', 'risk', 'capex', 'opex' ];
    console.log("simulation",simulation);
    const handleSetXAxis = (e) => {
        const value = e.target.value;
        if (axisOptions.includes(value)) {
            console.log('Entrato dentro', value, axis);
            setAxis({...axis, x: value});
        }
    };

    const handleSetYAxis = (e) => {
        const value = e.target.value;
        if (axisOptions.includes(value)) {
            setAxis({...axis, y: value});
        }
    };

    // Extract cost and risk data for each scenario
    const data = _scenarios.map(sc => ({
        scenario_id: sc.scenario_id,
        cost: sc.results.cum_actual_capex_opex.at(-1) / 1e6,  // Convert cost to millions
        risk: sc.results.cum_risk.at(-1),
        capex: sc.results.cum_actual_capex.at(-1) / 1e6,  // Convert cost to millions
        opex: sc.results.cum_actual_opex.at(-1) / 1e6,  
    }));

    return (
        <div ref={ref} style={{ width: '100%', height: '350px' }}>
            <div className="d-flex justify-content-between">
                <div style={{
                    width: '50%',
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <label>X Axis</label>
                    <Form.Select aria-label="Default select example"
                        value={axis.x}    
                    onChange={handleSetXAxis}
                    style={{
                        width: 'fit-content',
                    }}
                    >
                        {axisOptions && axisOptions.map((option) => (
                            <option key={option} value={option}>{option?.charAt(0).toUpperCase() + option.slice(1)}</option>
                        ))}

                        </Form.Select>
                </div>
                <div style={{
                    width: '50%',
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <label>Y Axis</label>
                    <Form.Select aria-label="Default select example"
                        value={axis.y}
                        onChange={handleSetYAxis}
                        style={{
                            width: 'fit-content',
                        }}    
                    >
                        {axisOptions && axisOptions.map((option) => (
                            <option key={option} value={option}>{option.charAt(0).toUpperCase() + option.slice(1)}</option>
                        ))}
                        
                    </Form.Select>
                </div>
            </div>
            {width && height && (
                <ScatterChart
                    width={width}
                    height={height-50}
                    margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                >
                    <CartesianGrid />
                    <XAxis
                        type="number"
                        dataKey={axis.x}
                        name={axis.x?.charAt(0).toUpperCase() + axis.x?.slice(1)} 
                        label={{ value: axis.x=== 'cost' ?'Cost (M€)': '', position: 'insideBottomRight', offset: -10 }}
                        tickFormatter={(value) => `${value} M€`} // Format x-axis as M€
                        domain={['auto', 'auto']} // Adjust domain automatically based on data
                    />
                    <YAxis
                        type="number"
                        dataKey={axis.y}
                        name={axis.y?.charAt(0).toUpperCase() + axis.y?.slice(1)}
                        label={{ value: axis.y==='risk'?'Risk':'', angle: -90, position: 'insideLeft' }}
                    />
                    <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                    <Scatter
                        name="Scenarios"
                        data={data}
                        fill="#8884d8"
                    >
                        <LabelList dataKey="scenario_id" position="right" /> {/* Labels next to each point */}
                    </Scatter>
                </ScatterChart>
            )}
        </div>
    );
}

export function TamComparisonTableChart({ simulation }) {
    const _scenarios = simulation.scenarios.filter(sc => !!sc.results && !sc.deleted);

    // Extract cost and risk data for each scenario
    const data = _scenarios.map((sc) => ({
        scenario_id: sc.scenario_id,
        cost: sc.results.cum_actual_capex_opex.at(-1) / 1e6, // Convert cost to millions
        capex: sc.results.cum_actual_capex.at(-1) / 1e6, // Convert cost to millions
        opex: sc.results.cum_actual_opex.at(-1) / 1e6, // Convert cost to millions
        risk: sc.results.cum_risk.at(-1),
        service: sc.results.service_level,
    }));

    const numberStyle = {
        textAlign: 'right',
        fontFamily: 'monospace',
    };

    // Configurazione per ogni colonna per definire se il valore migliore è più alto o più basso
    const criteria = {
        cost: 'low',
        capex: 'low',
        opex: 'low',
        risk: 'low',
        service: 'high',
    };

    // Funzione per calcolare la scala di colori da verde (migliore) a rosso (peggiore)
    const getColorScale = (value, column) => {
        const values = data.map((item) => item[column]);
        const min = Math.min(...values);
        const max = Math.max(...values);

        const isHigherBetter = criteria[column] === 'high';
        const relativeValue = isHigherBetter ? (value - min) / (max - min) : (max - value) / (max - min);

        // Utilizzo di color per creare una transizione graduale da rosso a verde
        const startColor = Color('#f56c6c'); // Rosso tenue
        const endColor = Color('#67c23a'); // Verde tenue

        return startColor.mix(endColor, relativeValue).hex();
    };

    return (
        <Table striped bordered hover>
            <thead>
            <tr>
                <th className="text-center">Scenario ID</th>
                <th className="text-center">Cost</th>
                <th className="text-center">Capex</th>
                <th className="text-center">Opex</th>
                <th className="text-center">Risk</th>
                <th className="text-center">Service</th>
            </tr>
            </thead>
            <tbody>
            {data.map((item, index) => (
                <tr key={index}>
                    <td>{item.scenario_id}</td>
                    <td style={{ ...numberStyle, backgroundColor: getColorScale(item.cost, 'cost') }}>
                        {item.cost.toFixed(2)} M€
                    </td>
                    <td style={{ ...numberStyle, backgroundColor: getColorScale(item.capex, 'capex') }}>
                        {item.capex.toFixed(2)} M€
                    </td>
                    <td style={{ ...numberStyle, backgroundColor: getColorScale(item.opex, 'opex') }}>
                        {item.opex.toFixed(2)} M€
                    </td>
                    <td style={{ ...numberStyle, backgroundColor: getColorScale(item.risk, 'risk') }}>
                        {item.risk.toFixed(2)}
                    </td>
                    <td style={{ ...numberStyle, backgroundColor: getColorScale(item.service, 'service') }}>
                        {(item.service * 100).toFixed(0)} %
                    </td>
                </tr>
            ))}
            </tbody>
        </Table>
    );
}