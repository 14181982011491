import {Spinner} from "react-bootstrap";
import {FaClock, FaTachometerAlt} from "react-icons/fa";
import {Fragment} from "react";

export function SimulationProfiler({simulationResults, simulation, pendingRequest}) {

    return <div className="d-flex">


        {simulationResults && <div >
            {/*<div>*/}
            {/*    <FaTachometerAlt style={{marginRight: '5px', color: '#007bff'}}/>*/}
            {/*    {(1 / computedUnits.simulation_time).toFixed(2)} scenari / s*/}
            {/*</div>*/}
            <div style={{fontSize: 30}}>
                <FaClock size={30} style={{marginRight: '7px', marginBottom: 6, color: '#007bff'}}/>
                {(simulationResults.simulation_time * 1000).toFixed(2)} ms
            </div>
            <small>Tempo impiegato dall'ultima simulazione.</small>

        </div>}

        <div style={{visibility: pendingRequest ? "unset" : "hidden", marginLeft: 5}}>
            <Spinner animation="border" variant="primary"/>
        </div>

    </div>

}
