// import logo from './logo.svg';

import 'bootstrap/dist/css/bootstrap.min.css';

import '/node_modules/react-grid-layout/css/styles.css'
import '/node_modules/react-resizable/css/styles.css'

import './components/rebus-custom-grid/rebus-custom-grid.css'

import './App.css';
import {Route, Switch} from "react-router-dom";
import {TecneTamNavBar} from "./components/nav-bar";

import {SimulationPage} from "./pages/simulation/simulation-page"; // TODO: dont remove for css

import {NewSimulationPage} from "./pages/new-simulation";
import {LoadingProvider, NotificationProvider} from "./AppContext";
import {SingleSimulationPage} from "./pages/simulations/simulation-editor";
import {AllSimulations} from "./pages/simulations/all-simulations";
import HomePage from "./pages/HomePage/home-page";

import {useEffect, useState } from 'react';


import NewInterventionsPage from './pages/newInterventions/newInterventions';


export default function App() {
    const [popUp, setPopUp] = useState({});


    function handleSetPopUp(popUp) {
        setPopUp({
            alert: popUp.alert,
            severity: popUp.severity,
            icon: popUp.icon
        });
    
        
        setTimeout(() => {
            setPopUp({
                alert: '',
                severity: '',
                icon: ''
            });
        }, 3000);
    }
    
    return <AppProviders>
        <TecneTamNavBar/>
        <div>
            <Switch>
                <Route path="/" exact><HomePage popUp={popUp} setPopUp={handleSetPopUp} /></Route>
                <Route path="/new-simulation" exact><NewSimulationPage /></Route>
                <Route path="/simulations" exact><AllSimulations /></Route>

                <Route path="/simulations/:simulationId" exact><SingleSimulationPage /></Route>
                <Route path="/simulations/:simulationId/scenarios/:scenarioId" exact><SingleSimulationPage /></Route>
            
                <Route path="/new-interventions" exact><NewInterventionsPage /></Route>
            </Switch>
        </div>
    </AppProviders>
}

function AppProviders({children}) {
    return <>
        <LoadingProvider>
            <NotificationProvider>
                {children}
            </NotificationProvider>
        </LoadingProvider>
    </>
}












