//create a page to add new interventions

import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Container } from 'react-bootstrap';
import { API } from '../../api/api';


export function NewInterventionsPage( ) {

    const [show, setShow] = useState(""); 
    const [interventionName, setInterventionName] = useState('');
    const [interventionColor, setInterventionColor] = useState('');
    const [interventions, setInterventions] = useState([]);

    useEffect(() => {
        API.getInterventions()
            .then(i => setInterventions(i))
        console.log(interventions)
    }, []);


    const handleAddInterventions = () => {
        if(interventionName === '' || interventionColor === '') {
            setShow('Please fill all fields')
            return
        }
        if(interventions.find(i => i.name === interventionName)) {
            setShow('Intervention already exists')
            return
        }
        API.addIntervention(interventionName, interventionColor)
            .then(() => {
                setShow('Intervention added successfully')
                setInterventionName('')
                setInterventionColor('')
                window.location.reload(); 
            })
            .catch((error) => {
                setShow('Failed to add intervention')
                console.error("Failed to add intervention", error);
            });
    }


    return <>
        <Modal show={show!==''} onHide={() => setShow('')} animation={false}>
            <Modal.Header closeButton
            style={{
                color: show!=='Intervention added successfully'?"rgba(253, 123, 127,.8)":"rgba(145, 206, 79,0.8)",
            }}>
                <Modal.Title>{show!=='Intervention added successfully'?"Warning":"Success"}</Modal.Title>
            </Modal.Header>
            <Modal.Body
            style={{
                backgroundColor: show!=='Intervention added successfully'?"rgba(253, 123, 127,.6)":"rgba(145, 206, 79,0.6)",
            }}
            >{show}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow('')}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
        <Container>
            <h1>Add New Interventions</h1>
            <Container>
                <Container>
                    <h3>Interventions</h3>
                        {interventions.map((intervention, index) => (
                        <Button style={{
                                backgroundColor:intervention.intervention_color,
                                border: 'none',
                                borderRadius: '2px',
                                width: 'fit-content',
                                marginLeft: '5px',
                                }}>
                                {intervention.intervention_name}
                            </Button>
                        ))}
                </Container>
                <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Intervention Name</Form.Label>
                        <Form.Control type="text" placeholder="Enter intervention name" value={interventionName} onChange={(e) => setInterventionName(e.target.value)} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Intervention Color</Form.Label>
                        <Form.Control type="text" placeholder="Enter intervention color" value={interventionColor} onChange={(e) => setInterventionColor(e.target.value)} />
                    </Form.Group>
                    <Button onClick={()=>{handleAddInterventions()}} variant="primary" >
                        Submit
                    </Button>
                </Form>
            </Container>
        </Container>
    </>
}


export default NewInterventionsPage;