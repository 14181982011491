import {Fragment} from "react";
import "./little-square-table.css"

export function LittleSquareTable({param, params, segments, years, firstYear, Square, squareProps}) {

    const is_multiple = !!params
    if (is_multiple) {
        param = params[0]
    }

    return <div className="mb-2">
        <table className="little-square-table conciTable">
            <tbody>
            <tr>
                <td colSpan="3"></td>
                {segments.map((seg, i) => <td key={i} colSpan={seg.n_units}>
                    {seg.segment_slug}
                </td>)}
            </tr>
            {param.map((year_units, sim_time) => <Fragment key={sim_time}>
                <tr>
                    <td className="td-year" colSpan={3}>{firstYear+sim_time}</td>
                    {year_units.map((unit, j) => <Fragment key={j}>
                        <Square
                            unitValue={is_multiple ? params.map(p => p[sim_time][j]) : unit}
                            unitId={j}
                            simulationTime={sim_time}
                            {...squareProps}
                        />
                    </Fragment>)}
                </tr>
            </Fragment>)}

            </tbody>
        </table>
    </div>
}