import {Button, Container, FormCheck, Table, Modal, Form} from "react-bootstrap";
import {useEffect, useState} from "react";
import {API} from "../api/api";
import {useNotification} from "../AppContext";
import {useHistory} from "react-router-dom";
import MapCanva from "../components/MapCanva/map-canva";
import './new-simulation.css';


export function NewSimulationPage(props) {

    const {pushMessage} = useNotification();
    const history = useHistory();

    const [sectionsAndParts, setSectionsAndParts] = useState([]);
    const [selectedSections, setSelectedSections] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [simulationDuration, setSimulationDuration] = useState(10);
    const [simulationYear, setSimulationYear] = useState(new Date().getFullYear());
    const [simulationName, setSimulationName] = useState("Nuova simulazione del "+new Date());
    const [galleries, setGalleries] = useState([]);
    const { setPopUp }  = props;
    
    useEffect(() => {
        const fetchSections = async () => {
            const data = await API.getSections();
            setSectionsAndParts(data);
        };
    
        fetchSections();
    }, []);

    const handleCheckboxChange = (section_id) => {
        setSelectedSections(prevSelectedSections => {
            if (prevSelectedSections.includes(section_id))
                return prevSelectedSections.filter(id => id !== section_id);
            else
                return [...prevSelectedSections, section_id];
        });
    };

    const handleSimula = () => {
        setShowModal(true);
    };

    const handleStartSimulation = () => {
        API.createSimulation(selectedSections, simulationYear, simulationDuration, simulationName)
            .then((response) => {
                pushMessage("Simulazione avviata");
                history.push(`/simulations/${response.simulation_id}`);
            })
            .catch((err) => {
                pushMessage("Simulazione non riuscita", "red");
            })
            .finally(() => setShowModal(false));
    };


    function handleAddGalleries(event) {
        if (!event.target.feature.properties.name || !event.target.feature.properties.name.includes('Galleria')) {
            return;
        }
        handleCheckboxChange(event.target.feature.properties.segment_code);
    }


    console.log("sectionsAndParts", sectionsAndParts); 

    return (
        <Container className="cont-new-sim">
            <Container className="cont-simulatio-settings">
                <Table striped bordered>
                    <tbody>
                    <tr>
                        <th>&nbsp;</th>
                        <th>Stone</th>
                        <th>Name</th>
                        <th>Short name</th>
                        <th>Type</th>
                        <th># Units</th>
                    </tr>
                    {sectionsAndParts.map((sp, i) => (
                        <tr key={i}>
                            <td className="text-center">
                                <FormCheck
                                    checked={selectedSections.includes(sp.segment_code)}
                                    onChange={() => handleCheckboxChange(sp.segment_code)}
                                />
                            </td>
                            <td>{sp.segment_code}</td>
                            <td>{sp.segment_name}</td>
                            <td>{sp.segment_slug}</td>
                            <td>Tunnel</td>
                            <td>{sp.n_units}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                    <Button variant="primary" onClick={handleSimula}style={{
                        marginLeft: "100px",
                    }}>Simula</Button>
            </Container>
            <MapCanva customOnClick={handleAddGalleries} selectedSections={selectedSections} fetchedData={sectionsAndParts} />
        

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Nuova simulazione</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><strong>Simula {selectedSections.length} gallerie</strong>: {selectedSections.join(', ')}</p>
                    <Form.Group controlId="simName">
                        <Form.Label>Nome simulazione</Form.Label>
                        <Form.Control
                            type="text"
                            value={simulationName}
                            onChange={(e) => setSimulationName(e.target.value)}
                            placeholder="Nome simulazione"
                        />
                    </Form.Group>
                    <Form.Group controlId="simulationDuration" className="mt-3">
                        <Form.Label># Anni di simulazione</Form.Label>
                        <Form.Control
                            type="number"
                            value={simulationDuration}
                            onChange={(e) => setSimulationDuration(Number(e.target.value))}
                            placeholder="Anni di simulazione"
                        />
                    </Form.Group>
                    <Form.Group controlId="simulationYear" className="mt-3">
                        <Form.Label>Anno di inizio simulazione</Form.Label>
                        <Form.Control
                            type="number"
                            value={simulationYear}
                            onChange={(e) => setSimulationYear(Number(e.target.value))}
                            placeholder="Anno di inizio simulazione"
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>Annulla</Button>
                    <Button variant="primary" onClick={handleStartSimulation}>OK</Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}
