const base_api = process.env.REACT_APP_BASE_API;

function getTunnels() {
    return apiRequest(base_api + "tunnels");
}

function getGroups() {
    return apiRequest(base_api + "groups")
}

function getInterventionSchedulers() {
    return apiRequest(base_api + "intervention-schedulers");
}

function getSections() {
    return apiRequest(base_api + "sections")
}

function getSimulations() {
    return apiRequest(base_api + "simulations")
}


function getSimulation(simulation_id) {
    return apiRequest(base_api + "simulations/"+simulation_id)
}

function getInterventions() {
    return apiRequest(base_api + "interventions")
}


function createGroup(group_name, stone_codes) {
    return apiRequest(base_api + "groups", {
        method: "POST",
        body: JSON.stringify({group_name, stone_codes})
    });
}

function createSimulation(segment_codes, current_year, simulation_duration, simulation_name) {
    return apiRequest(base_api + "simulations", {
        method: "POST",
        body: JSON.stringify({segment_codes, current_year, simulation_duration, simulation_name})
    });
}

function computeSimulation(simulation_id, scenario_id, force_rehab, rules=[]) {
    return apiRequest(base_api +`simulations/${simulation_id}/${scenario_id}/compute`, {
        method: "POST",
        body: JSON.stringify({force_rehab, rules})
    });
}

function updateSimulationRules(simulation_id, simulation_rules) {
    return apiRequest(base_api +`simulations/${simulation_id}/rules`, {
        method: "POST",
        body: JSON.stringify(simulation_rules)
    });
}

function addScenario(simulation_id) {
    return apiRequest(base_api +`simulations/${simulation_id}/scenarios`, {
        method: "POST",
        body: JSON.stringify({})
    });
}

function deleteScenario(simulation_id, scenario_id) {
    return apiRequest(base_api +`simulations/${simulation_id}/scenarios/${scenario_id}`, {
        method: "DELETE"
    });
}


async function apiRequest(url, options = {}) {
    if (options.method === "POST") {
        if (!options.headers)
            options.headers = {}
        options.headers["Content-Type"] = "application/json"
    }
    try {
        const response = await fetch(url, options);
        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || "Network response was not ok");
        }
        return await response.json();
    } catch (error) {
        throw error;
    }
}


async function updateScenario(simulation_id, scenario_id, newName) { 
    return apiRequest(base_api + `simulations/${simulation_id}/scenarios/${scenario_id}`, {
        method: "PUT",
        body: JSON.stringify({
           scenario_name: newName, // Make sure to match the expected key
        }),
        headers: {
            "Content-Type": "application/json" // Set the correct content type
        }
    });
}

async function editSimulation(simulation, newConfig) {
    console.log("new config", newConfig);
    if(simulation.simulation_name!==newConfig.simulation_name){
        simulation.simulation_name = newConfig.simulation_name
    }
    //delete simulation_name from newConfig
    delete newConfig.simulation_name;
    simulation.config = { ...simulation.config, ...newConfig };
    console.log("New simulation", simulation);
    return apiRequest(base_api + `simulations/${simulation.simulation_id}`, {
        method: "PUT",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(simulation) 
    });
}


async function addIntervention(interventionName, interventionColor) {
    return apiRequest(base_api + "interventions", {
        method: "POST",
        body: JSON.stringify({intervention_name: interventionName, intervention_color: interventionColor})
    });
}


async function saveSimulationGUIConfig(simulation_id, gui_config) {
    console.log("GUI Config:", gui_config);

    return apiRequest(base_api + `simulations/${simulation_id}/gui-config`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(gui_config)
    });
}



export const API = {
    getTunnels,
    getGroups,
    createGroup,
    getInterventionSchedulers,
    getSections,
    createSimulation,
    getSimulation,
    getInterventions,
    computeSimulation,
    updateSimulationRules,
    addScenario,
    deleteScenario,
    updateScenario,
    getSimulations,
    editSimulation,
    addIntervention,
    saveSimulationGUIConfig
}