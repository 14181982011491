import React, { useState } from "react";
import { Dropdown } from 'react-bootstrap';
import "./AnalysisSelect.css";

const AnalysisSelect = ({ analysis_classes = [], selectedClass, setAnalysis_class }) => {
    
    const [isOpen, setIsOpen] = useState(false);

    const handleSelect = (analysis_class) => {
        setAnalysis_class(analysis_class);
        setIsOpen(false); 
    };

    const handleOver = (analysis_class) => {
        setAnalysis_class(analysis_class);
    };

    const toggleOpen = () => setIsOpen(!isOpen);

    return (
        <div>
            <Dropdown show={isOpen} onToggle={toggleOpen}>
                <Dropdown.Toggle className='CustomDropdown' id="dropdown-basic">
                    {selectedClass}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {analysis_classes.length > 0 ? (
                        analysis_classes.map((analysisClass, index) => (
                            <Dropdown.Item
                                className="CustomDropdownItem"
                                key={index}
                                eventKey={analysisClass}
                                onSelect={() => handleSelect(analysisClass)}
                                onMouseOver={() => handleOver(analysisClass)}
                            >
                                {analysisClass}
                            </Dropdown.Item>
                        ))
                    ) : (
                        <Dropdown.Item disabled>Nessun ambito disponibile</Dropdown.Item>
                    )}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};

export default AnalysisSelect;
