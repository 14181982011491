import React, {Fragment, useEffect, useState} from 'react';
import {Table, Button, Form, Container, ButtonGroup} from 'react-bootstrap';
import {FaArrowDown, FaArrowUp, FaTrash} from "react-icons/fa";
import {NONE_INTERVENTION} from "../simulation-editor";

const STD_RULES = [
    { 
        "when_minimum": 2,
        "condition": "SLO",
        "lower_then": 0.4,
        "higher_then": 0,
        "intervention": "IMS",
        "on_same_segment": true,
        "overwrite": true,
        "active": true
    },
    {
        "when_minimum": 5,
        "condition": "SGG",
        "lower_then": 0.4,
        "higher_then": 0,
        "intervention": "IRS",
        "on_same_segment": true,
        "overwrite": true,
        "active": true
    },
    {
        "when_minimum": 1,
        "condition": "SGG",
        "lower_then": 0.3,
        "higher_then": 0,
        "intervention": "TRS",
        "on_same_segment": true,
        "overwrite": false,
        "active": true
    },
    {
        "when_minimum": 1,
        "condition": "SLO",
        "lower_then": 0.3,
        "higher_then": 0,
        "intervention": "TRS",
        "on_same_segment": true,
        "overwrite": false,
        "active": true
    }
]

export function RulesManager({simulation, refresh, interventions, rules, setRules}) {



    const handleChange = (index, field, value) => {
        const newRules = [...rules];
        newRules[index][field] = value;
        setRules(newRules);
    };


    const addRule = () => {
        setRules([...rules, {
            when_minimum: 1,
            condition: 'SLO',
            lower_then: 0.5,
            higher_then: 0,
            intervention: "TRS",
            on_same_segment: false,
            overwrite: false,
            active: true
        }]);
    };

    const deleteRule = (index) => {
        const newRules = rules.filter((_, i) => i !== index);
        setRules(newRules);
    };

    const moveRuleUp = (index) => {
        if (index === 0) return; // non fa nulla se è la prima regola
        const newRules = [...rules];
        const temp = newRules[index - 1];
        newRules[index - 1] = newRules[index];
        newRules[index] = temp;
        setRules(newRules);
    };

    const moveRuleDown = (index) => {
        if (index === rules.length - 1) return; // non fa nulla se è l'ultima regola
        const newRules = [...rules];
        const temp = newRules[index + 1];
        newRules[index + 1] = newRules[index];
        newRules[index] = temp;
        setRules(newRules);
    };

    useEffect(() => {
        refresh()
        // API.updateSimulationRules(simulation.simulation_id, rules)
        //     .then(_ => {
        //         refresh()
        //     })
    }, [rules]);

    return <div style={{maxWidth: 1200}}>
        <Table bordered striped className="mt-2" style={{tableLayout: "fixed"}}>
            <thead>
            <tr>
                <th>When minimum</th>
                <th style={{width: "7%"}}>On same tunnel</th>
                <th>Have condition</th>
                <th>Lower then</th>
                <th>Higher then</th>
                <th>Do intervention</th>
                <th style={{width: "7%"}}>Over write</th>
                <th style={{width: "7%"}}>Active</th>
                <th style={{width: "20%"}}>&nbsp;</th>
            </tr>
            </thead>
            <tbody>
            {rules.map((rule, index) => (
                <tr key={index}>
                    <td>
                        <Form.Control
                            type="number"
                            value={rule.when_minimum}
                            onChange={(e) => handleChange(index, 'when_minimum', e.target.value)}
                            min="1"
                            max="100"
                        />
                    </td>
                    <td >
                        <Form.Check
                            type="switch"
                            checked={rule.on_same_segment}
                            onChange={(e) => handleChange(index, 'on_same_segment', e.target.checked)}
                            label=""
                            className="text-center"
                        />
                    </td>

                    <td>
                        <Form.Control
                            as="select"
                            value={rule.condition}
                            onChange={(e) => handleChange(index, 'condition', e.target.value)}
                        >
                            <option value="SGG">SGG</option>
                            <option value="SLO">SLO</option>
                        </Form.Control>
                    </td>
                    <td>
                        <Form.Control
                            type="number"
                            value={rule.lower_then}
                            onChange={(e) => handleChange(index, 'lower_then',
                                parseFloat(e.target.value) || 0)}
                            step="0.01"
                        />
                    </td>
                    <td>
                        <Form.Control
                            type="number"
                            value={rule.higher_then}
                            onChange={(e) => handleChange(index, 'higher_then',
                                parseFloat(e.target.value) || 0)}
                            step="0.01"
                        />
                    </td>
                    <td>
                        <Form.Control
                            as="select"
                            value={rule.intervention}
                            onChange={(e) => handleChange(index, 'intervention', e.target.value)}
                        >
                            {interventions.map((int, i) => <Fragment key={i}>
                                <option value={int.intervention_name}>{int.intervention_name}</option>
                            </Fragment>)}
                            <option value={NONE_INTERVENTION}>None</option>
                        </Form.Control>
                    </td>
                    <td >
                        <Form.Check
                            type="switch"
                            checked={rule.overwrite}
                            onChange={(e) => handleChange(index, 'overwrite', e.target.checked)}
                            label=""
                            className="text-center"
                        />
                    </td>

                    <td >
                        <Form.Check
                            type="switch"
                            checked={rule.active}
                            onChange={(e) => handleChange(index, 'active', e.target.checked)}
                            label=""
                            className="text-center"
                        />
                    </td>
                    <td>
                        <ButtonGroup>
                            <Button variant="light" onClick={() => moveRuleUp(index)}>
                                <FaArrowUp />
                            </Button>
                            <Button variant="light" onClick={() => moveRuleDown(index)}>
                                <FaArrowDown />
                            </Button>
                            <Button variant="danger" onClick={() => deleteRule(index)}>
                                <FaTrash />
                            </Button>
                        </ButtonGroup>
                    </td>
                </tr>
            ))}
            <tr>
                <td colSpan="9">
                    <Button variant="success" onClick={addRule}>New rule</Button> &nbsp;
                    <Button variant="outline-secondary"  onClick={() => setRules(STD_RULES)}>Load standard rules</Button> &nbsp;

                    <Button variant="outline-secondary" onClick={() => {
                        navigator.clipboard.writeText(JSON.stringify(rules))
                    }}>Copy</Button> &nbsp;

                    <Button variant="outline-secondary" onClick={() => {
                        navigator.clipboard.readText()
                            .then(text => setRules(rl => JSON.parse(text)))
                    }}>Paste</Button>
                </td>
            </tr>
            </tbody>
        </Table>
    </div>
}
