import React, { Fragment, PureComponent } from 'react';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    LineChart,
    Line
} from 'recharts';
import { Card } from "react-bootstrap";
import { GrMoney, GrUserWorker } from "react-icons/gr";
import { CgDanger } from "react-icons/cg";
import { TbBuildingBridge2, TbCar } from "react-icons/tb";
import { useResizeDetector } from 'react-resize-detector';

export function ReportSummaryBox({ simulation, simulationResults }) {
    const { width, ref } = useResizeDetector();

    if (!simulation || !simulationResults) return <></>;

    const baseFontSize = width ? Math.min(35, Math.max(16, width / 20)) : 31; // Calcola la dimensione del font in base alla larghezza del container, con un massimo di 35pt

    const tableStyle = {
        fontSize: `${baseFontSize}pt`,
        fontFamily: 'Barlow Condensed',
        width:"100%",
    };

    const textStyle = {
        fontSize: `${baseFontSize * 0.9}pt`, // Riduci leggermente la dimensione del testo
        fontWeight: 'bold',
        textAlign: 'right',
        fontFamily: 'monospace',
    };

    const iconStyle = {
        fontSize: `${baseFontSize * 0.9}pt`, // Riduci leggermente la dimensione delle icone
        lineHeight: `${baseFontSize * 0.9}pt`,
        paddingBottom: '0px',
        paddingRight: '6px',
    };

    const capex = simulationResults.cum_actual_capex.at(-1);
    const opex = simulationResults.cum_actual_opex.at(-1);

    return (
        <div ref={ref} style={{ width: '100%', height: '100%' }}>
            <table style={tableStyle}>
                <tbody>
                    {/* Cost */}
                    <tr>
                        <td style={iconStyle} rowSpan="2"><GrMoney /></td>
                        <td style={textStyle} rowSpan="2">
                            &nbsp;{(simulationResults.cum_actual_capex_opex.at(-1) / 1e6).toFixed(2)}
                        </td>
                        <td rowSpan="2"><small>&nbsp;Cost M€</small>&nbsp;&nbsp;</td>
                        <td style={{ ...textStyle, fontSize: `${baseFontSize * 0.7}pt`, lineHeight: `${baseFontSize * 0.7}pt`, textAlign: 'right', paddingRight: '10px' }}>
                            Capex: {(capex / 1e6).toFixed(2)}
                            &nbsp;(<small>{
                                String((capex / (capex + opex) * 100).toFixed(0)).padStart(2, ' ')
                            }%</small>)
                        </td>
                    </tr>
                    <tr>
                        <td style={{ ...textStyle, fontSize: `${baseFontSize * 0.7}pt`, lineHeight: `${baseFontSize * 0.7}pt`, textAlign: 'right', paddingRight: '10px' }}>
                            Opex: {(opex / 1e6).toFixed(2)}
                            &nbsp;(<small>{
                                String((opex / (capex + opex) * 100).toFixed(0)).padStart(2, ' ')
                            }%</small>)
                        </td>
                    </tr>

                    {/* Risk */}
                    <tr>
                        <td style={iconStyle}><CgDanger /></td>
                        <td style={textStyle}>
                            {simulationResults.cum_risk.at(-1).toFixed(0)}
                        </td>
                        <td><small>&nbsp;Risk</small></td>
                    </tr>

                    {/* Service */}
                    <tr>
                        <td style={iconStyle}><TbCar /></td>
                        <td style={textStyle}>
                            {(simulationResults.service_level * 100).toFixed(0)}
                        </td>
                        <td><small>&nbsp;Service</small></td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export function RiskReport({ simulation, simulationResults }) {
    const { width, height, ref } = useResizeDetector();

    if (!simulation || !simulationResults) return <></>;

    const data = simulationResults.cum_risk.map((_, i) => ({
        name: simulation.config.current_year + i,
        cum_risk: simulationResults.cum_risk[i]
    }));

    const formatCurrency = (value) => `${(value / 1).toFixed(0)}`; // Format values in M€ with 2 decimal places
    const formatLegend = (value) => {
        const label = {
            'cum_risk': 'Risk',
        }[value] || value;

        return <span style={{ whiteSpace: 'nowrap' }}>{label}</span>;
    };

    return (
        <Fragment>
            <div ref={ref} style={{ width: '100%', height: '100%' }}>
            <LineChart
                width={width || 500}
                height={height || 300}
                data={data}
                margin={{
                    top: 5,
                    right: 10,
                    left: 15,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis tickFormatter={formatCurrency} /> {/* Format Y-axis labels */}
                <Tooltip formatter={formatCurrency} /> {/* Format tooltip values */}
                <Legend formatter={formatLegend} /> {/* Customize legend labels */}
                <Line type="monotone" dataKey="cum_risk" stroke="#00aeed" dot={false} animationDuration={0} />
            </LineChart>
            </div>
        </Fragment>
    );
}

export function EconomicReport({ simulation, simulationResults }) {
    const { width, height, ref } = useResizeDetector();

    if (!simulation || !simulationResults) return <></>;

    const data = simulationResults.cum_actual_capex_opex.map((_, i) => ({
        name: simulation.config.current_year + i,
        cum_actual_capex_opex: simulationResults.cum_actual_capex_opex[i],
        cum_actual_capex: simulationResults.cum_actual_capex[i],
        cum_actual_opex: simulationResults.cum_actual_opex[i],
    }));

    const formatCurrency = (value) => `${(value / 1e6).toFixed(2)}\u00A0M€`; // Format values in M€ with 2 decimal places
    const formatLegend = (value) => {
        const label = {
            'cum_actual_capex': 'Capex',
            'cum_actual_opex': 'Opex',
            'cum_actual_capex_opex': 'Capex + Opex'
        }[value] || value;

        return <span style={{ whiteSpace: 'nowrap' }}>{label}</span>;
    };

    return (
        <Fragment>
            <div ref={ref} style={{ width: '100%', height: '100%' }}>
            <LineChart
                width={width||500}
                height={height||300}
                data={data}
                margin={{
                    top: 5,
                    right: 10,
                    left: 30,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis tickFormatter={formatCurrency} /> {/* Format Y-axis labels */}
                <Tooltip formatter={formatCurrency} /> {/* Format tooltip values */}
                <Legend formatter={formatLegend} /> {/* Customize legend labels */}
                <Line type="monotone" dataKey="cum_actual_capex" stroke="#00aeed" dot={false} animationDuration={0} /> {/* Remove points and animations */}
                <Line type="monotone" dataKey="cum_actual_opex" stroke="#7030a0" dot={false} animationDuration={0} /> {/* Remove points and animations */}
                <Line type="monotone" dataKey="cum_actual_capex_opex" stroke="#000000" dot={false} animationDuration={0} /> {/* Remove points and animations */}
            </LineChart>
            </div>
        </Fragment>
    );
}

export function StatusReport({ simulation, simulationResults }) {
    const { width, height, ref } = useResizeDetector();

    if (!simulation || !simulationResults) return <></>;

    return (
        <div ref={ref} style={{ width: '100%', height: '100%' }}>
            <BarChart
                width={width || 500}
                height={height || 300}
                data={simulationResults.param_counter_sgg_slo.map((el, i) => ({
                    1: el[1], 2: el[2], 3: el[3], 4: el[4], name: i + simulation.config.current_year
                }))}
                margin={{
                    top: 5,
                    right: 5,
                    left: 0,
                    bottom: 30,
                }}
            >
                <CartesianGrid
                    vertical={false}  // Rimuove le linee verticali
                    stroke={"#ccc"}
                />
                <XAxis
                    dataKey="name"
                    tick={{ angle: 90, textAnchor: 'end' }}
                    dx={7}
                    dy={35}  // Abbassa le etichette
                    interval={0}  // Mostra tutte le etichette
                    stroke="#000"
                />
                <YAxis />
                <Tooltip />
                <Bar dataKey="1" stackId="a" fill="#91ce4f" isAnimationActive={false} />
                <Bar dataKey="2" stackId="a" fill="#fde498" isAnimationActive={false} />
                <Bar dataKey="3" stackId="a" fill="#fdbe00" isAnimationActive={false} />
                <Bar dataKey="4" stackId="a" fill="#fd7b7f" isAnimationActive={false} />
            </BarChart>
        </div>
    );
}