import {useEffect, useRef, useState} from "react";
import {GridElement} from "../../components/rebus-custom-grid/rebus-custom-grid";
import {ConfigRulesAndParams} from "./scenario-editor-components/config-rules-and-params";
import {ManualScheduler, ScheduleResults} from "./scenario-editor-components/manual-scheduler";
import {SimulationProfiler} from "./scenario-editor-components/simulation-profiler";
import {EconomicReport, ReportSummaryBox, RiskReport, StatusReport} from "./scenario-editor-components/economic-and-status-report";
import {Responsive, WidthProvider} from "react-grid-layout";
import {API} from "../../api/api";

const ResponsiveGridLayout = WidthProvider(Responsive);

export function ScenarioEditor ({scenario, simulation, interventions, refreshMirrorSimulation, mirrorSimulation}) {

    /* grid system */
    // TODO: prendere da <- simulation.gui_config

    const [layouts, setLayouts] = useState(simulation.gui_config || { lg: [] });
    console.log("layouts", layouts);

    function handleSetLayouts(newLayouts) {
        console.log("setL, Lg: ", newLayouts);
        setLayouts({ lg: newLayouts });
    }

    useEffect(() => {
 
        console.log("saving layouts", layouts);
        if (layouts && layouts.lg) {
            API.saveSimulationGUIConfig(simulation.simulation_id, layouts);
        }
    }, [layouts, simulation.gui_config, simulation.simulation_id]);


    const [rules, setRules] = useState(mirrorSimulation.scenarios[scenario.scenario_id].execution_parameters.rules);

    const [interventionPlans, setInterventionPlans] = useState(scenario.execution_parameters.force_rehab)
    const [simulationResults, setSimulationResults] = useState(undefined)
    useEffect(() => {
        setRules(scenario.execution_parameters.rules)
        setInterventionPlans(scenario.execution_parameters.force_rehab)
        setSimulationResults(undefined)
        setRules(mirrorSimulation.scenarios[scenario.scenario_id].execution_parameters.rules)
    }, [scenario]);

    const [pendingRequest, setPendingRequest] = useState(false);
    const [dirtyFlag, setDirtyFlag] = useState(1)
    const latestRequest = useRef(0);

    useEffect(() => {
        if (pendingRequest) {
            return;
        } // cancellata
        if (latestRequest.current >= dirtyFlag) {
            return;
        } // eseguita}

        setPendingRequest(true)
        latestRequest.current = dirtyFlag + 1;

        API.computeSimulation(
            simulation.simulation_id,
            scenario.scenario_id,
            interventionPlans,
            rules
        )
            .then(res => {
                setSimulationResults(res)
                refreshMirrorSimulation()
            })
            .finally(_ => {
                setPendingRequest(false)
                setDirtyFlag(x => x + 1);
            })

    }, [dirtyFlag]);


    return <>

        <ResponsiveGridLayout
            className="layout"
            layouts={layouts}
            isResizable={true}
            rowHeight={30}
            breakpoints={{lg: 0}}
            cols={{lg: 12}}
            onLayoutChange={(lg)=>{console.log("ciao");handleSetLayouts(lg)}}
        >

            <div key="set-rules"
            >
                <GridElement title="Set Rules">
                    <ConfigRulesAndParams
                        simulation={simulation}
                        setDirtyFlag={setDirtyFlag}
                        interventions={interventions}
                        rules={rules}
                        setRules={setRules}
                    />
                </GridElement>
            </div>

            <div key="scheduler"
            
            >
                <GridElement title="Scheduler">
                    <ManualScheduler
                        simulation={simulation}
                        interventions={interventions}
                        {...{
                            dirtyFlag,
                            setDirtyFlag,
                            interventionPlans,
                            setInterventionPlans,
                            computedUnits: simulationResults,
                            setComputedUnits: setSimulationResults
                        }}
                    />
                </GridElement>
            </div>
            <div key="schedule-results"
            
            >
                <GridElement title="Scheduler">
                    <ScheduleResults {...{simulation, computedUnits: simulationResults, setComputedUnits: setSimulationResults}} />
                </GridElement>
            </div>
            <div key="profiler"
            
            >
                <GridElement title="Profiler">
                    <SimulationProfiler simulationResults={simulationResults} simulation={simulation} pendingRequest={pendingRequest} />
                </GridElement>
            </div>
            <div key="status-report"
            
            >
                <GridElement title="Status Report SGG+SLO">
                    <StatusReport
                        simulationResults={simulationResults}
                        simulation={simulation}
                    />
                </GridElement>
            </div>
            <div key="economic-report"
            
            >
                <GridElement title="Cum. Disc. Capex and Opex ">
                    <EconomicReport
                        simulationResults={simulationResults}
                        simulation={simulation}
                    />
                </GridElement>
            </div>
            <div key="risk-report"
            
            >
                <GridElement title="Cum. Risk ">
                    <RiskReport
                        simulationResults={simulationResults}
                        simulation={simulation}
                    />
                </GridElement>
            </div>

            <div key="summary"
            
            >
                <GridElement title="Summary">
                    <ReportSummaryBox
                        simulationResults={simulationResults}
                        simulation={simulation}
                    />
                </GridElement>
            </div>
        </ResponsiveGridLayout>
    </>
}