import {GrDrag} from "react-icons/gr";
import {Fragment} from "react";

export function GridElement({children, title, fixed=false}) {
    return <div style={{width: "100%", height: "100%"}} className="d-flex flex-column pb-3">

        <div
            className="rebus-grid-element-header d-flex justify-content-between"
            style={{width: '100%', cursor: fixed ? "unset" : "grab"}}
        >
            <div className=""><strong>{title}</strong></div>
            {!fixed && <div className="">
                <GrDrag/>
            </div>}
        </div>

        <div
            className={"rebus-grid-element"}
            style={{height: "100%", overflowX: "hidden", overflowY: "scroll"}}
            onClick={e => e.stopPropagation()}
            onMouseDown={e => e.stopPropagation()}
            onDragStart={e => e.stopPropagation()}
        >
            <div className="rebus-grid-element-body">
                {children}
            </div>
        </div>
    </div>
}

// const dragHandleStyle = {
//     position: 'absolute',
//     bottom: '5px',
//     right: '5px',
//     cursor: 'move',
//     backgroundColor: '#ccc',
//     padding: '5px',
//     borderRadius: '3px',
// };
