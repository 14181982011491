import React from 'react';
import { Container, ListGroup } from 'react-bootstrap';
import './HighwaysList.css'; // Import the CSS file

function hexToRgb(hex) {
  hex = hex.replace(/^#/, '');
  var bigint = parseInt(hex, 16);
  var r = (bigint >> 16) & 255;
  var g = (bigint >> 8) & 255;
  var b = bigint & 255;

  return `${r}, ${g}, ${b}`;
}

function HighwaysList(props) {
  const { highways, analysis_class, setSegment, getColorBasedOnCDA, selectedSections, filters} = props;
  if (!highways) return null;

  const toShow = highways.filter((el) => el.properties.name);
  const uniqueByName = new Map(toShow.map(el => [el.properties.name.indexOf('_')>0?el.properties.name.substring(0,el.properties.name.indexOf('_')):el.properties.name, el]));
  const uniqueToShow = Array.from(uniqueByName.values());
  const middleIndex = Math.ceil(uniqueToShow.length / 2);
  const firstHalf = uniqueToShow.slice(0, middleIndex);
  const secondHalf = uniqueToShow.slice(middleIndex);

  return (
    <Container className="highwaylistContainer">
      <ListGroup className="list-group">
        {firstHalf.map((el, index) => {
          if(!el.properties.name && el.properties.name.includes("undefined")) return null;
          const isSelected = (filters.segment 
            ? (filters.segment === (el.properties.name.indexOf('_') > 0 
                ? el.properties.name.substring(0, el.properties.name.indexOf('_')) 
                : el.properties.name) 
              ? true
              : false) 
            : false);
          return (
          <ListGroup.Item
            key={index}
            style={{
              marginRight: '5px',
              backgroundColor: `rgba(${hexToRgb(getColorBasedOnCDA(el, analysis_class, selectedSections))}, 0.5)`,
              boxShadow: isSelected ? `0 0 10px rgba(${hexToRgb(getColorBasedOnCDA(el, analysis_class, selectedSections))}, 0.5)` : 'none', 
              transform: isSelected ? 'scale(1.1)' : 'none', 
  
            }}
            onClick={() => setSegment(el.properties.name.indexOf('_')>0?el.properties.name.substring(0,el.properties.name.indexOf('_')):el.properties.name)}
            className={
              "highwaylist-group-item"
            }          >
          {el.properties.name.indexOf('_')>0?el.properties.name.substring(0,el.properties.name.indexOf('_')):el.properties.name}
          </ListGroup.Item>
        )})}
      </ListGroup>
      <ListGroup className="list-group">
        {secondHalf.map((el, index) => (
          el.properties.name && !el.properties.name.includes("undefined") ?
          <ListGroup.Item
            key={index + middleIndex} 
            className="highwaylist-group-item"
            style={{
              backgroundColor: `rgba(${hexToRgb(getColorBasedOnCDA(el, analysis_class, selectedSections))}, 0.5)`,
            }}
            onClick={() => setSegment(el.properties.name.indexOf('_')>0?el.properties.name.substring(0,el.properties.name.indexOf('_')):el.properties.name)}
          >
            {el.properties.name.indexOf('_')>0?el.properties.name.substring(0,el.properties.name.indexOf('_')):el.properties.name}
            </ListGroup.Item>:null
        ))}
      </ListGroup>
    </Container>
  );
}

export default HighwaysList;
