import MapCanva from "../../components/MapCanva/map-canva";
import Alert from'@mui/material/Alert';
import './home-page.css';

function HomePage(props){
    const {popUp, setPopUp} = props;
    return (<>
        {popUp.alert && (
      <Alert onClose={() => setPopUp({
              severity: "",
              alert: '',
          })} icon={popUp.icon} severity={popUp.severity}>
          {popUp.alert}
      </Alert>
  )}
        <MapCanva />
        </>
    );
}


export default HomePage;