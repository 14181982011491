import { Modal, Tab, Tabs, Button, Form, Container, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { API } from "../../api/api";
import { LittleSquareTable } from "../../components/little-square-table/little-square-table";
import { BigContainer } from "../../components/big-container";
import { ScenariosNavigator } from "./scenarios-navigator";
import { ScenarioEditor } from "./scenario-editor";
import { TamComparisonTableChart, TamScatterChart } from "../portfolio-components/comparison";
import { GridElement } from "../../components/rebus-custom-grid/rebus-custom-grid";

export const NONE_INTERVENTION = "----";

export function SingleSimulationPage({}) {
    let { simulationId, scenarioId } = useParams();
    if (!scenarioId) scenarioId = "0"; // TODO = ATTENZIONE SE CAMBI IN ARRAY

    const [simulation, setSimulation] = useState(undefined); // info sulla specifica simulazione
    const [interventions, setInterventions] = useState([]); // possibili interventi
    const [modal, setModal] = useState(false);
    const [mirrorSimulation, setMirrorSimulation] = useState(undefined);
    const [dirtySimulationFlag, setDirtySimulationFlag] = useState(0);
    const refreshMirrorSimulation = () => setDirtySimulationFlag((x) => x + 1);
    const modifiable = ["discount_rate", "simulation_name", "impact_sgg", "impact_slo", "capex_for_intervention", "opex_for_intervention"];

    const [modifiedField, setModifiedField] = useState({});

    useEffect(() => {
        API.getSimulation(simulationId).then((s) => setSimulation(s));
        API.getInterventions().then((i) => setInterventions(i));
    }, [simulationId]);

    useEffect(() => {
        API.getSimulation(simulationId).then((s) => setMirrorSimulation(s));
    }, [dirtySimulationFlag]);

    useEffect(() => {
        setModifiedField(
            simulation
                ? {
                      ...Object.entries(simulation || {}).reduce((acc, [key, value]) => {
                          if (modifiable.includes(key)) {
                              acc[key] = value;
                          }
                          return acc;
                      }, {}),
                      ...Object.entries(simulation.config || {}).reduce((acc, [key, value]) => {
                          if (modifiable.includes(key)) {
                              acc[key] = value;
                          }
                          return acc;
                      }, {}),
                  }
                : {}
        );
    }, [simulation]);

    if (!simulation || !mirrorSimulation) return <>Loading ...</>;

    const handleEditSim = (modifiedField) => {
        const newConfig = {};
        Object.entries(modifiedField).forEach(([key, value]) => {
            if (value !== "") newConfig[key] = value;
        });
        API.editSimulation(simulation, newConfig).then(() => {
            setModal(false);
            refreshMirrorSimulation();
        });
    };

    const renderFormFields = (fields, setModifiedField, parentKey = '') => {
        return Object.entries(fields).map(([key, value]) => {
            const fullKey = parentKey ? `${parentKey}.${key}` : key;

            if (typeof value === 'object' && value !== null) {
                return (
                    <div key={fullKey}>
                        <h5>{fullKey}</h5>
                        {renderFormFields(value, setModifiedField, fullKey)}
                    </div>
                );
            } else if (key === 'simulation_name' || key === 'discount_rate') {
                return (
                    <Form.Group key={fullKey} controlId={`form-${fullKey}`}>
                        <h5>{fullKey}</h5>
                        <Form.Control
                            type="text"
                            value={value}
                            onChange={(e) => {
                                const newValue = e.target.value;
                                setModifiedField((oldModifiedField) => {
                                    const newFields = { ...oldModifiedField };
                                    const keys = fullKey.split('.');
                                    let temp = newFields;
                                    for (let i = 0; i < keys.length - 1; i++) {
                                        temp = temp[keys[i]];
                                    }
                                    temp[keys[keys.length - 1]] = newValue;
                                    return newFields;
                                });
                            }}
                            placeholder={`Insert ${fullKey}`}
                        />
                    </Form.Group>
                );
            } else {
                return (
                    <Form.Group key={fullKey} controlId={`form-${fullKey}`}>
                        <Form.Label>{fullKey}</Form.Label>
                        <Form.Control
                            type="text"
                            value={value}
                            onChange={(e) => {
                                const newValue = e.target.value;
                                setModifiedField((oldModifiedField) => {
                                    const newFields = { ...oldModifiedField };
                                    const keys = fullKey.split('.');
                                    let temp = newFields;
                                    for (let i = 0; i < keys.length - 1; i++) {
                                        temp = temp[keys[i]];
                                    }
                                    temp[keys[keys.length - 1]] = newValue;
                                    return newFields;
                                });
                            }}
                            placeholder={`Insert ${fullKey}`}
                        />
                    </Form.Group>
                );
            }
        });
    };

    return (
        <BigContainer style={{ backgroundColor: '#eeeeef', paddingBottom: "100px" }}>
            <div>
                {/* SIMULATION CONFIG */}
                <Modal show={modal} onHide={() => setModal(false)} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Modifica Configurazione</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                        <Form>
                            <Row>
                                <Col md={6}>
                                    {modifiedField && renderFormFields(modifiedField, setModifiedField)}
                                </Col>
                                <Col md={6}>
                                    {/* Aggiungi altri campi o contenuti qui se necessario */}
                                </Col>
                            </Row>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Container style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%"
                        }}>
                            <Button variant="success" onClick={() => handleEditSim(modifiedField)}>Invia</Button>
                            <Button variant="danger" onClick={() => setModal(false)}>Chiudi</Button>
                        </Container>
                    </Modal.Footer>
                </Modal>

                <div className="d-flex flex-column">
                    <div className="d-flex">
                        <div className="m-2" style={{ width: "40%", height: "fit-content", backgroundColor: "#ffffff", border: "1px solid #c3c4c7" }}>
                            <GridElement title="Scenario Scatter" fixed>
                                {mirrorSimulation && <TamScatterChart simulation={mirrorSimulation} />}
                            </GridElement>
                        </div>

                        <div className="m-2" style={{ width: "50%", backgroundColor: "#ffffff", border: "1px solid #c3c4c7" }}>
                            <GridElement title="Scenarios" fixed>
                                {mirrorSimulation && <TamComparisonTableChart simulation={mirrorSimulation} />}
                            </GridElement>
                        </div>

                        <div className="m-2" style={{ width: "10%", backgroundColor: "#ffffff", border: "1px solid #c3c4c7" }}>
                            <GridElement title="Options" fixed>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%"
                                }} className="m-2">
                                    <Button style={{
                                        width: "300px",
                                        height: "50px"
                                    }} onClick={() => setModal(true)}>
                                        Modifica config
                                    </Button>
                                </div>
                            </GridElement>
                        </div>
                    </div>

                </div>
            </div>

            <ScenariosNavigator
                simulation={simulation}
                scenarios={simulation.scenarios}
                scenarioId={scenarioId}
            />

            {/*SINGLE SCENARIO*/}
            {scenarioId && <ScenarioEditor
                scenario={simulation.scenarios[scenarioId]}
                simulation={simulation}
                interventions={interventions}
                refreshMirrorSimulation={refreshMirrorSimulation}
                mirrorSimulation={mirrorSimulation}
            />}
        </BigContainer>
    );
}

function SimulationStartup({ simulation }) {
    const tabs = {
        "SGG (deg)": "param_sgg_degmodel",
        "SLO (deg)": "param_slo_degmodel",
        "SGG": "param_sgg_numerical",
        "SLO": "param_slo_numerical",
        "Built (year)": "units_building_year",
        "Names": "units_codes",
    };
    return (
        <Tabs className="mb-3">
            {Object.entries(tabs).map(([tab_name, parameter], i) => (
                <Tab eventKey={tab_name} title={tab_name} key={i}>
                    <LittleSquareTable
                        years={1}
                        firstYear={simulation.config.current_year}
                        param={[simulation.units_data[parameter]]}
                        segments={simulation.segments}
                        Square={CdaSquare}
                        squareProps={{}}
                    />
                </Tab>
            ))}
        </Tabs>
    );
}

function CdaSquare({ unitValue }) {
    return (
        <td className="little-square-inner user-select-none text-nowrap" title={unitValue}>
            {unitValue}
        </td>
    );
}

export default SingleSimulationPage;