import { Fragment, useState, useEffect, useRef } from "react";
import { API } from "../../api/api";
import { Dropdown } from 'react-bootstrap';
import { Link, useHistory } from "react-router-dom";

export function ScenariosNavigator({ simulation, scenarios, scenarioId }) {
    const history = useHistory();
    const [contextMenu, setContextMenu] = useState(null);
    const [toUpdateScenario, setToUpdateScenario] = useState(null);
    const [newScenarioName, setNewScenarioName] = useState("");
    const menuRef = useRef(null);

    const handleContextMenu = (event, scenarioId) => {
        event.preventDefault();
        setContextMenu({ x: event.clientX, y: event.clientY, scenarioId });
    };


    const handleUpdateName = () => {
        if (toUpdateScenario !== null && newScenarioName.trim()) {
            API.updateScenario(simulation.simulation_id, toUpdateScenario, newScenarioName.trim())
                .then(() => {
                    handleCloseContextMenu();
                    window.location.reload(); 
                })
                .catch((error) => {
                    console.error("Failed to update scenario", error);
                });
        }
    };
    

    const handleDeleteScenario = (scenarioId) => {
        API.deleteScenario(simulation.simulation_id, scenarioId)
            .then((res) => {
                console.log(res);
                setContextMenu(null);
                if(res == null)
                    window.location.href = `/simulations/${simulation.simulation_id}`;
                else
                window.location.href = `/simulations/${simulation.simulation_id}/scenarios/${res}`;
            })
            .catch((error) => {
                console.error("Failed to delete scenario", error);
                setContextMenu(null);
            });
    };

    const handleCloseContextMenu = () => {
        setContextMenu(null);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                handleCloseContextMenu();
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <>
            <div className="scenario-navigator mt-3">
                {scenarios.map((s) => (
                    <Fragment key={s.scenario_id}>
                        {toUpdateScenario === s.scenario_id ? (
                            <input
                                type="text"
                                defaultValue={s.scenario_name}
                                onChange={(event) => setNewScenarioName(event.target.value)}
                                onBlur={(event) => {
                                    handleUpdateName(s.scenario_id, newScenarioName);
                                    setToUpdateScenario(null);
                                }}
                                onKeyPress={(event) => {
                                    if (event.key === 'Enter') {
                                        handleUpdateName(s.scenario_id, newScenarioName);
                                        setToUpdateScenario(null);
                                    }
                                }}
                                autoFocus
                            />
                        ) :
                        <Link
                            to={`/simulations/${simulation.simulation_id}/scenarios/${s.scenario_id}`}
                            className={
                                "scenario-single-label " +
                                (scenarioId == s.scenario_id ? "scenario-selected" : "")
                            }
                            onContextMenu={(event) => handleContextMenu(event, s.scenario_id)}
                            style={{
                                display: s.deleted ? "none" : undefined,
                                textDecoration: "unset",
                                color: "black"
                            }}
                        >
                            {s.scenario_name}
                        </Link>}
                    </Fragment>
                ))}

                <div
                    className={"scenario-single-label"}
                    onClick={() => {
                        API.addScenario(simulation.simulation_id).then((newScenario) => {
                            console.log("NewScenario:", newScenario);
                            if (newScenario && newScenario.scenario_id) {
                                window.location.href = `/simulations/${simulation.simulation_id}/scenarios/${newScenario.scenario_id}`;
                            } else {
                                console.error("Failed to add new scenario: Invalid response", newScenario);
                            }
                        }).catch((error) => {
                            console.error("Failed to add new scenario", error);
                        });
                    }}
                    style={{ display: undefined }}
                >
                    + New
                </div>
            </div>

            {contextMenu && (
                <Dropdown
                    show={true}
                    ref={menuRef}
                    style={{
                        position: 'absolute',
                        top: contextMenu.y,
                        left: contextMenu.x,
                        zIndex: 1000,
                    }}
                >
                    <Dropdown.Menu show={true} className="show">
                    <Dropdown.Item
                            onClick={() => {
                                setToUpdateScenario(contextMenu.scenarioId);
                                handleCloseContextMenu();
                            }}
                        >
                            Rename Scenario
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={() => {
                                handleDeleteScenario(contextMenu.scenarioId);
                                handleCloseContextMenu();
                            }}
                        >
                            Delete Scenario
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={handleCloseContextMenu}
                        >
                            Annulla
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            )}
        </>
    );
}